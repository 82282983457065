<template>
    <form
        @submit.prevent="$emit('submit')"
        novalidate
        class="form-t"
        :class="{
            'form-t_no-style': noStyle
        }"
    >
        <h1
            v-if="$slots.title"
            class="form-t__title"
        >
            <slot name="title"/>
        </h1>
        <div
            v-if="$slots.desc"
            class="form-t__desc"
        >
            <slot name="desc"/>
        </div>
        <div class="form-t__content">
            <slot/>
        </div>
        <div
            v-if="$slots.bottom"
            class="form-t__bottom"
        >
            <slot name="bottom"/>
        </div>
        <div class="form-t__actions">
            <base-button type="submit" mode="yellow" class="">
                {{ action || 'Продолжить' }}
            </base-button>
        </div>
    </form>
</template>

<script>
import './form-t.scss'

export default {
    name: 'FormTemplate',
    props: {
        action: {
            type: String
        },
        noStyle: {
            type: Boolean,
            default: false
        }
    }
}
</script>